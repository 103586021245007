<template>
  <div
    v-if="!$store.getters['common/isLoading']"
    class="d-flex flex-column"
  >
    <div class="d-flex flex-column flex-sm-row mb-1">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="1"
        ><h5 class="h5-text mt-2">Логи</h5>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="2"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dateRange.start"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRange.start"
                label="Дата начало"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange.start"
              :first-day-of-week="1"
              locale="ru-ru"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dateRange.start)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="2"
        >
          <v-menu
            ref="menuEnd"
            v-model="menuEnd"
            :close-on-content-click="false"
            :return-value.sync="dateRange.end"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRange.end"
                label="Дата конец"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange.end"
              no-title
              scrollable
              :first-day-of-week="1"
              locale="ru-ru"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menuEnd = false"
              >
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menuEnd.save(dateRange.end)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="2"
        >
          <v-btn
            depressed
            color="primary"
            @click="applyFilterDate(dateRange.start, dateRange.end)"
            block
          >
            Применить
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="exportFile()"
            :loading="fileLoading"
            class="mt-2"
            block
          >
            Сформировать файл
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="2"
        ></v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-text-field
            background-color="white"
            v-model="search"
            name="search"
            prepend-inner-icon="$search"
            placeholder="Поиск"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-data-table
      :headers="logs.headers"
      :items="logs.items"
      :sort-by="'id'"
      :sort-desc="true"
      :search="search"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
      <template v-slot:item.data="{ item }">
        <span v-html="item.data"></span>
      </template>
      <template v-slot:item.event="{ item }">
        <span v-html="item.event"></span>
      </template>
    </v-data-table>
    <div class="mt-3">
      <v-pagination class="d-inline-block" v-model="currentPage" :length="pageCount" total-visible="10"
                    @input="navigate"></v-pagination>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
// import Component from 'vue-class-component'
import { Http } from '@/apis/Http'

export default {
  data() {
    return {
      search: '',
      fileLoading: false,
      logs: {
        headers: [],
        items: []
      },

      dateRange: {
        start: '',
        end: ''
      },

      menu: false,
      menuEnd: false,
      currentPage: 1,
      pageCount: 1,
      itemsPerPage: 100,
      prevPage: 1
    }
  },
  created() {
    this.$setLoading(true)
    this.getLogs()
  },
  methods: {

    navigate() {
      if (this.currentPage !== this.prevPage) {
        this.$setLoading(true)
        this.getLogs()
      }
    },

    getStartDate() {
      const date = new Date
      return date.toISOString().substr(0, 10)
    },

    getLogs() {
      Http.get('/admin/logs', {
        params: {
          startDate: this.dateRange.start,
          endDate: this.dateRange.end,
          page: this.currentPage,
          limit: this.itemsPerPage
        }
      })
        .then(response => {
          this.logs.headers = response.data.headers
          this.logs.items = response.data.data.items
          this.pageCount = Math.ceil(response.data.data.total / this.itemsPerPage)
          this.prevPage = parseInt(this.currentPage)
        })
        .catch(() => {
          this.showErrorSystemNotification('Ooops!')
        })
        .finally(() => {
          this.$setLoading(false)
        })
    },

    applyFilterDate() {
      if (this.dateRange.start !== '' && this.dateRange.end !== '') {
        this.$setLoading(true)
        this.currentPage = 1
        this.getLogs()
      } else {
        this.showErrorSystemNotification('Не заполнены поля даты')
      }
    },

    exportFile() {
      if (this.dateRange.start === '' && this.dateRange.end === '') {
        this.showErrorSystemNotification('Не заполнены поля даты')
        return
      }

      this.fileLoading = true

      Http.get('/admin/logs-export', {
        params: {
          startDate: this.dateRange.start,
          endDate: this.dateRange.end,
          all: 1
        },
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          const fileName = response.headers?.['content-disposition'] ? response.headers['content-disposition'].split('filename=')[1] : 'logs-' + this.dateRange.start + '-' + this.dateRange.end + '.xlsx'
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(() => {
          this.showErrorSystemNotification('Ooops!')
        })
        .finally(() => {
          this.fileLoading = false
        })
    }
  }
}
</script>
